<template>
  <div class="charts-box">
    <div class="title">
      {{ setDecimalBit(thisWeekArr) }}
      <span
        :class="{
          chain: thisWeekArr === 0,
          'chain-top': chainSystem === '+∞' || chainSystem > 0,
          'chain-down': chainSystem < 0,
        }"
      >
        {{ chainSystem }}%
        <i
          v-if="chainSystem === '+∞' || chainSystem > 0"
          class="el-icon-caret-top"
        ></i>
        <i v-else class="el-icon-caret-bottom"></i>
      </span>
    </div>
    <div class="charts">
      <qiun-vue-ucharts type="line" :opts="opts" :chartData="chartData" />
    </div>
  </div>
</template>

<script>
// 需要在您的项目内执行 npm i @qiun/vue-ucharts
import qiunVueUcharts from "@qiun/vue-ucharts";
import http from "@/http/index.js";
export default {
  components: {
    qiunVueUcharts,
  },
  data() {
    return {
      tenantNum: localStorage.getItem("tenantNum"),
      chartData: {},
      chainSystem: "",
      thisWeekArr: "",
      //您可以通过修改 config-ucharts.js 文件中下标为 ['line'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
      opts: {
        color: [
          "#1890FF",
          "#91CB74",
          "#FAC858",
          "#EE6666",
          "#73C0DE",
          "#3CA272",
          "#FC8452",
          "#9A60B4",
          "#ea7ccc",
        ],
        padding: [15, 20, 0, 15],
        enableScroll: false,
        //  legend: {
        //   show: false
        // },
        xAxis: {
          disableGrid: true,
          axisLine: false,
          disabled: true,
        },
        yAxis: {
          disabled: true,
          disableGrid: true,
          gridType: "dash",
          dashLength: 2,
          data: [
            {
              axisLine: false,
              max: null,
              min: 0,
            },
          ],
        },
        extra: {
          line: {
            type: "curve",
            width: 2,
            activeType: "hollow",
          },
        },
      },
    };
  },
  mounted() {
    this.getServerData();
  },
  methods: {
    getServerData() {
      http({
        url: "/api/home/laokj",
        method: "GET",
        params: {
          tenantNum: this.tenantNum,
        },
      }).then((res) => {
        let resl = {
          categories: [],
          series: [
            {
              name: "现金",
              data: [],
            },
          ],
        };
        const thisWeek = res.data.data.ThisWeektotalCash.reduce(
          (total, value) => total + value,
          0
        );
        const lastWeek = res.data.data.LastWeektotalCash.reduce(
          (total, value) => total + value,
          0
        );
        let formattedDifference;
        if (lastWeek !== 0) {
          const difference = ((thisWeek - lastWeek) / lastWeek) * 100;
          formattedDifference = difference.toFixed(2);
        } else {
          formattedDifference = "+∞";
        }
        this.thisWeekArr = thisWeek;
        this.chainSystem = formattedDifference;
        const max = Math.max(...res.data.data.ThisWeektotalCash);
        const thirtyPercent = max + Math.ceil(max * 0.3);
        this.opts.yAxis.data[0].max = thirtyPercent;
        resl.categories = res.data.data.ThisWeekDays;
        resl.series[0].data = res.data.data.ThisWeektotalCash;
        this.chartData = JSON.parse(JSON.stringify(resl));
      });
    },
    setDecimalBit(num) {
      if (num === 0) {
        return num + ".00";
      } else if (num === undefined) {
        return 0;
      } else {
        const [integerPart, decimalPart] = num.toString().split(".");
        let result = "";

        // 处理整数部分
        const len = integerPart.length;
        for (let i = len - 1, j = 0; i >= 0; i--, j++) {
          result = integerPart[i] + result;
          if (j % 3 === 2 && i !== 0) {
            result = "," + result;
          }
        }

        // 添加小数部分
        if (decimalPart !== undefined) {
          const roundedNum = (
            Math.round(parseFloat("0." + decimalPart) * 100) / 100
          ).toFixed(2);
          result += "." + roundedNum.split(".")[1];
        } else {
          result += ".00";
        }

        return result;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-box {
  .title {
    font-size: 20px;
    font-weight: 600;
    .chain-top {
      font-size: 12px;
      color: red;
      margin-left: 5px;
    }
    .chain-down {
      font-size: 12px;
      color: green;
      margin-left: 5px;
    }
    .chain {
      font-size: 12px;
      margin-left: 5px;
    }
  }
  .charts {
    height: 100%;
  }
}
</style>
