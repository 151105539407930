<template>
  <div class="home">
    <HomePage></HomePage>
    <div class="home-row">
      <NoAccess v-if="shouldShowComponent"></NoAccess>
      <div class="one-left" v-if="!shouldShowComponent">
        <div class="two-top">
          <div class="top-title">
            <div class="title-signboard" v-if="topIf == 1">营业额</div>
            <div>
              <el-date-picker
                class="data_picker"
                v-model="valueDate"
                type="month"
                placeholder="选择月"
                value-format="yyyy-MM"
                @change="getRefresh"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="top-charts">
            <div class="topIf_1" v-if="topIf == 1">
              <div class="charts-box">
                <qiun-vue-ucharts
                  type="line"
                  :opts="opts1"
                  :chartData="chartDataGraph"
                  style="padding-top: 10px"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="dialogCash">
          <el-dialog :title="cashValue" :visible.sync="cashCenter">
            <div class="week">
              <div class="lastWeek">
                <table border="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th>上周</th>
                      <th>金额</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(synthesizeLast, index) in lastWeekList[0].date"
                      :key="index"
                    >
                      <td>
                        {{ synthesizeLast }}
                      </td>
                      <td>{{ lastWeekList[0].cash[index] }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>合计</th>
                      <th>{{ calculateTotalLast }}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="thisWeek">
                <table border="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th>本周</th>
                      <th>金额</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(synthesizeThis, index) in thisWeekList[0].date"
                      :key="index"
                    >
                      <td>
                        {{ synthesizeThis }}
                      </td>
                      <td>{{ thisWeekList[0].cash[index] }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>合计</th>
                      <th>{{ calculateTotalThis }}</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </el-dialog>
        </div>
        
        <div class="two-center">
          <div class="three-left">
            <div class="left-cash">
              现金<span style="font-size: 12px; color: #c4c4c4">(近7日)</span
              ><span
                class="more"
                @click="(cashCenter = true), (cashValue = '现金'), cashArr()"
                >查看更多</span
              >
            </div>
            <div class="left-progressBar">
              <CashPage></CashPage>
            </div>
          </div>
          <div class="three-center">
            <div class="three-cash">
              卡扣<span style="font-size: 12px; color: #c4c4c4">(近7日)</span
              ><span
                class="more"
                @click="(cashCenter = true), (cashValue = '卡扣'), cashArr()"
                >查看更多</span
              >
            </div>
            <div class="three-progressBar">
              <ClipPage></ClipPage>
            </div>
          </div>
          <div class="three-right">
            <div class="right-cash">
              退款<span style="font-size: 12px; color: #c4c4c4">(近7日)</span
              ><span
                class="more"
                @click="(cashCenter = true), (cashValue = '退款'), cashArr()"
                >查看更多</span
              >
            </div>
            <div class="three-progressBar">
              <RefundPage></RefundPage>
            </div>
          </div>
        </div>
        <div class="two-bottom">
          <div class="bottom-charts">
            <StackedBarChartPage
              :initial-year="stackedGetMonth()"
            ></StackedBarChartPage>
          </div>
        </div>
      </div>
      <div class="one-right" v-if="!shouldShowComponent">
        <div class="right-title">关键数据</div>
        <div class="right-particulars">
          <div class="particulars-1">
            <div class="title">顾客总数</div>
            <div class="number">
              {{ TotalNumberCustomers
              }}<span style="font-size: 12px; color: #979797; font-werght: 100"
                >人</span
              >
            </div>
          </div>
          <div class="particulars-1">
            <div class="title">日营业额</div>
            <div class="number">
              {{ setDecimalBitProFun(dailyTurnover)
              }}<span style="font-size: 12px; color: #979797; font-werght: 100"
                >￥</span
              >
            </div>
          </div>
          <div class="particulars-1">
            <div class="title">日服务单</div>
            <div class="number">
              {{ dailServiceOrderVolume
              }}<span style="font-size: 12px; color: #979797; font-werght: 100"
                >单</span
              >
            </div>
          </div>
          <div class="particulars-1">
            <div class="title">日充卡单</div>
            <div class="number">
              {{ dailyCardCharge
              }}<span style="font-size: 12px; color: #979797; font-werght: 100"
                >单</span
              >
            </div>
          </div>
          <div class="particulars-1">
            <div class="title">短信余量</div>
            <div class="number">
              {{ smsMargin
              }}<span style="font-size: 12px; color: #979797; font-werght: 100"
                >条</span
              >
            </div>
          </div>
        </div>
        <div class="right-charts">
          <div class="left">
            <ProductCharts></ProductCharts>
          </div>
          <div class="right">
            <ProjectCharts></ProjectCharts>
          </div>
        </div>
        <div
          style="
            margin-left: 15px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 10px;
          "
        >
          提成一览
        </div>
        <div class="switchover">
          <div
            :class="switchoverValue == 1 ? 'product' : 'public'"
            @click="switchoverProduct"
          >
            产&nbsp;品
          </div>
          <div
            :class="switchoverValue == 2 ? 'project' : 'public'"
            @click="switchoverProject"
          >
            项&nbsp;目
          </div>
        </div>
        <div class="percentage-table">
          <table class="table-head" border="0" cellspacing="0">
            <thead class="head-1" v-if="switchoverValue == 1">
              <tr>
                <th>美容师</th>
                <th>产品</th>
                <th>提成</th>
                <th>时间</th>
              </tr>
            </thead>
            <thead class="head-2" v-if="switchoverValue == 2">
              <tr>
                <th>美容师</th>
                <th>项目</th>
                <th>提成</th>
                <th>手工提成</th>
                <th>时间</th>
              </tr>
            </thead>
          </table>
          <div class="table-body-border">
            <table class="table-body" border="0" cellspacing="0">
              <tbody class="body-1" v-if="switchoverValue == 1">
                <tr
                  v-for="(product, index) in conven.staffProductCommissions"
                  :key="index + 'tp'"
                >
                  <td>{{ product.staffName }}</td>
                  <td>{{ JSON.parse(product.productJson).productName }}</td>
                  <td>{{ product.commissionPrice }}</td>
                  <td>{{ product.createTime }}</td>
                </tr>
              </tbody>
              <tbody class="body-2" v-if="switchoverValue == 2">
                <tr
                  v-for="(project, index) in conven.staffProjectCommissions"
                  :key="index + 'ty'"
                >
                  <td>{{ project.staffName }}</td>
                  <td>{{ JSON.parse(project.projectJson).projectName }}</td>
                  <td>{{ project.commissionPrice }}</td>
                  <td>{{ project.manualCommissionPrice }}</td>
                  <td>{{ project.createTime }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qiunVueUcharts from "@qiun/vue-ucharts";
import HomePage from "@/components/HomePage.vue";
import StackedBarChartPage from "@/components/Home/uCharts/StackedBarChartPage";
import http from "../http/index.js";
import { setDecimalBit, setDecimalBitPro } from "../utils/publicMethods";
import NoAccess from "../components/Denied/DeniedPage.vue";
import CashPage from "@/components/Home/uCharts/Performance/CashPage";
import ClipPage from "@/components/Home/uCharts/Performance/ClipPage";
import RefundPage from "@/components/Home/uCharts/Performance/RefundPage";
import ProjectCharts from "@/components/Home/uCharts/Annular/ProjectCharts";
import ProductCharts from "@/components/Home/uCharts/Annular/ProductCharts";
export default {
  components: {
    HomePage,
    StackedBarChartPage,
    qiunVueUcharts,
    CashPage,
    ClipPage,
    RefundPage,
    ProjectCharts,
    ProductCharts,
    NoAccess,
  },
  data() {
    return {
      topIf: 1,
      tenantNum: localStorage.getItem("tenantNum"),
      smsMargin: localStorage.getItem("smsMargin"),
      dailyCardCharge: "", //充卡单
      dailServiceOrderVolume: "", //总服务单
      dailyTurnover: "", // 营业额
      TotalNumberCustomers: "", // 总顾客
      options: [
        {
          value: "1",
          label: "项目提成",
        },
        {
          value: "2",
          label: "产品提成",
        },
      ],
      cashCenter: false,
      cashValue: "",
      valueDeduct: "1",
      conven: [],
      cashForPeopleArr: {},
      valueDate: "",
      chartDataGraph: {},
      opts1: {
        color: [
          "#1890FF",
          "#91CB74",
          "#FAC858",
          "#EE6666",
          "#73C0DE",
          "#3CA272",
          "#FC8452",
          "#9A60B4",
          "#ea7ccc",
        ],
        padding: [15, 20, 0, 15],
        enableScroll: false,
        legend: {},
        xAxis: {
          disableGrid: true,
          axisLine: false,
        },
        dataPointShape: false,
        yAxis: {
          gridType: "dash",
          dashLength: 2,
          disableGrid: true,
          data: [
            {
              max: null,
              axisLine: false,
            },
          ],
        },
        extra: {
          line: {
            type: "curve",
            width: 2,
            activeType: "hollow",
            linearType: "custom",
            onShadow: true,
          },
        },
      },
      lastWeekList: [
        {
          date: [],
          cash: [],
        },
      ],
      thisWeekList: [
        {
          date: [],
          cash: [],
        },
      ],
      switchoverValue: 1,
    };
  },
  created() {
    this.getYearMonth();
  },
  mounted() {
    this.database();
    this.convenient();
    this.getGraphData();
  },
  computed: {
    currentElement() {
      return this.elements[this.currentIndex];
    },
    shouldShowComponent() {
      const isAdmin = localStorage.getItem("Administrator").includes("admin");
      const hasHomePanelPermission = localStorage
        .getItem("PermissionList")
        .includes("home.panel");

      if (isAdmin && !hasHomePanelPermission) {
        return false;
      } else if (!isAdmin && !hasHomePanelPermission) {
        return true;
      } else {
        return false;
      }
    },
    calculateTotalLast() {
      return this.lastWeekList[0].cash.reduce(
        (total, value) => total + value,
        0
      );
    },
    calculateTotalThis() {
      return this.thisWeekList[0].cash.reduce(
        (total, value) => total + value,
        0
      );
    },
  },
  methods: {
    setDecimalBitProFun(val) {
      return setDecimalBitPro(val);
    },
    getRefresh() {
      if (this.topIf == 1) {
        this.getGraphData();
      }
    },
    getGraphData() {
      http({
        url:
          "/api/home/turnover/?month=" +
          this.valueDate +
          "&tenantNum=" +
          this.tenantNum +
          "&SwitchingCode=" +
          1,
        method: "GET",
      }).then((res) => {
        let resl = {
          categories: [],
          series: [
            {
              name: "营业额",
              linearColor: [
                [0, "#1890FF"],
                [0.25, "#00B5FF"],
                [0.5, "#00D1ED"],
                [0.75, "#00E6BB"],
                [1, "#90F489"],
              ],
              data: [],
            },
            {
              name: "服务单",
              linearColor: [
                [0, "#91CB74"],
                [0.25, "#2BDCA8"],
                [0.5, "#2AE3A0"],
                [0.75, "#C4D06E"],
                [1, "#F2D375"],
              ],
              data: [],
            },
            {
              name: "充卡单",
              linearColor: [
                [0, "#FAC858"],
                [0.33, "#FFC371"],
                [0.66, "#FFC2B2"],
                [1, "#FA7D8D"],
              ],
              data: [],
            },
          ],
        };
        const max = Math.max(
          ...res.data.data.TotalTurnover,
          ...res.data.data.TotalServiceOrderTurnover,
          ...res.data.data.TotalCardChargingOrderTurnover
        );
        const thirtyPercent = max + Math.ceil(max * 0.3);
        this.opts1.yAxis.data[0].max = thirtyPercent;
        resl.categories = res.data.data.mmdd;
        resl.series[0].data = res.data.data.TotalTurnover;
        resl.series[1].data = res.data.data.TotalServiceOrderTurnover;
        resl.series[2].data = res.data.data.TotalCardChargingOrderTurnover;
        this.chartDataGraph = JSON.parse(JSON.stringify(resl));
      });
    },
    //保留数字小数点后两位数
    setDecimalBitFun(val, m) {
      return setDecimalBit(val, m);
    },
    getYearMonth() {
      const nowDate = new Date();
      const year = nowDate.getFullYear();
      const month = nowDate.getMonth() + 1;
      const newMonth = month >= 10 ? month : "0" + month;
      this.valueDate = year + "-" + newMonth;
    },
    stackedGetMonth() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
      };
      return date.year;
    },
    database() {
      http({
        url: "/api/home/ImportantData/?tenantNum=" + this.tenantNum,
        method: "GET",
      }).then((res) => {
        // console.log(res);
        this.dailyCardCharge = res.data.data.DailyCardCharge; //日充卡单
        this.dailServiceOrderVolume = res.data.data.DailyServiceOrderVolume; // 日服务单
        this.dailyTurnover = res.data.data.DailyTurnover; //日营业额
        this.TotalNumberCustomers = res.data.data.TotalNumberCustomers; //顾客总数
      });
    },
    async convenient() {
      const res = await http({
        url: "/api/home/listCommission/",
        method: "GET",
        params: {
          tenantNum: this.tenantNum,
          exchange: "123",
        },
      });
      // console.log(res);
      this.conven = res.data.data;
      this.staffMsgProject();
      this.staffMsgProduct();
    },
    async staffMsgProject() {
      const staffArr = this.conven.staffProjectCommissions.map((i) => {
        return http({
          url: "/api/tenant/staff/" + i.staffId,
          method: "GET",
          data: {},
        }).then((res) => {
          return res.data.data;
        });
      });
      const results = await Promise.all(staffArr);
      this.conven.staffProjectCommissions =
        this.conven.staffProjectCommissions.map((i, index) => {
          const data = results[index];
          if (i.staffId == data.id) {
            return {
              ...i,
              staffName: data.staffName,
            };
          }
          return i;
        });
    },
    async staffMsgProduct() {
      const staffArr = this.conven.staffProductCommissions.map((i) => {
        return http({
          url: "/api/tenant/staff/" + i.staffId,
          method: "GET",
          data: {},
        }).then((res) => {
          return res.data.data;
        });
      });
      const results = await Promise.all(staffArr);
      this.conven.staffProductCommissions =
        this.conven.staffProductCommissions.map((i, index) => {
          const data = results[index];
          if (i.staffId == data.id) {
            return {
              ...i,
              staffName: data.staffName,
            };
          }
          return i;
        });
    },
    cashArr() {
      http({
        url: "/api/home/laokj",
        method: "GET",
        params: {
          tenantNum: this.tenantNum,
        },
      }).then((res) => {
        this.lastWeekList[0].date = res.data.data.LastWeekDays;
        this.thisWeekList[0].date = res.data.data.ThisWeekDays;
        if (this.cashValue == "现金") {
          this.lastWeekList[0].cash = res.data.data.LastWeektotalCash;
          this.thisWeekList[0].cash = res.data.data.ThisWeektotalCash;
        } else if (this.cashValue == "卡扣") {
          this.lastWeekList[0].cash = res.data.data.LastWeekTotalConsumption;
          this.thisWeekList[0].cash = res.data.data.ThisWeekTotalConsumption;
        } else {
          this.lastWeekList[0].cash = res.data.data.LastWeekTotalRefundAmount;
          this.thisWeekList[0].cash = res.data.data.ThisWeekTotalRefundAmount;
        }
      });
    },
    switchoverProduct() {
      this.switchoverValue = 1;
    },
    switchoverProject() {
      this.switchoverValue = 2;
    },
  },
};
</script>

<style lang="scss">
.home {
  display: flex;
  .home-row {
    width: calc(100% - 72px);
    display: flex;
    background-color: #fff;
    height: 100vh;
    .one-left {
      width: 62%;
      margin-left: 2%;
      height: 98vh;
      .dialogCash {
        .el-dialog {
          margin-top: 10vh !important;
          .el-dialog__body {
            .week {
              display: flex;
              width: 90%;
              margin-left: 5%;
              .lastWeek {
                width: 50%;
                margin-right: 50px;
                table {
                  width: 100%;
                  border: 1px solid #f0f1f3;
                  thead {
                    text-align: center;
                    background-color: #f9fafa;
                    tr {
                      height: 50px;

                      th {
                        width: 50%;
                      }
                    }
                  }
                  tbody {
                    text-align: center;
                    tr {
                      height: 50px;
                      td {
                        border-bottom: 1px solid #f0f1f3;
                      }
                    }
                  }
                  tfoot {
                    text-align: center;
                    background-color: #f7f8fa;
                    tr {
                      height: 50px;
                    }
                  }
                }
              }
              .thisWeek {
                width: 50%;
                table {
                  width: 100%;
                  thead {
                    text-align: center;
                    background-color: #f9fafa;
                    tr {
                      height: 50px;
                    }
                  }
                  tbody {
                    text-align: center;
                    tr {
                      height: 50px;
                    }
                  }
                  tfoot {
                    text-align: center;
                    background-color: #f7f8fa;
                    tr {
                      height: 50px;
                    }
                  }
                }
              }
            }
          }
        }
      }
     
      .two-top {
        background-color: #fff;
        height: 35%;
        margin-bottom: 2%;
        margin-top: 2%;
        border-radius: 10px;
        // box-shadow: 0px 1px 6px rgb(194, 192, 192);
        .top-charts {
          width: 100%;
          height: 78%;
          .topIf_1 {
            width: 100%;
            height: 100%;
          }
        }

        .top-title {
          display: flex;
          justify-content: space-between;
          height: 20%;
          .title-signboard {
            font-size: 26px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 400;
            margin-left: 5%;
            margin-top: 1%;
          }
          .title-btn {
            margin-right: -35%;
            margin-top: 1%;
            border: 1px solid #d9d9d9;
            height: 70%;
            width: 23%;
            border-radius: 20px;
            .btn-blue {
              transition: 0.5s;
              background-color: #52b7f5;
              color: #fff;
            }
            button {
              border: none;
              border-radius: 15px;
              height: 80%;
              width: 30%;
              margin-left: 2.5%;
              margin-top: 1.6%;
              background-color: #fff;
              color: #979797;
            }
          }
        }
      }
      .two-center {
        background-color: #fff;
        height: 26%;
        margin-bottom: 30px;
        border-radius: 10px;
        display: flex;
        .three-left {
          background-color: #fff;
          width: 32%;
          height: 230px;
          margin-right: 2%;
          border-radius: 10px;
          // box-shadow: 0px 1px 6px #c2c0c0;
          .left-cash {
            font-size: 18px;
            font-weight: 600;
            margin-top: 1%;
            .more {
              color: #165dff;
              font-size: 13px;
              margin-left: 150px;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .left-progressBar {
            margin-top: 2%;
            height: 90%;
          }
        }
        .three-center {
          background-color: #fff;
          width: 32%;
          height: 230px;
          margin-right: 2%;
          border-radius: 10px;
          // box-shadow: 0px 1px 6px #c2c0c0;
          .three-cash {
            font-size: 18px;
            font-weight: 600;
            margin-top: 1%;
            .more {
              color: #165dff;
              font-size: 13px;
              margin-left: 150px;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .three-progressBar {
            margin-top: 2%;
            height: 90%;
          }
        }
        .three-right {
          background-color: #fff;
          width: 32%;
          height: 230px;
          border-radius: 10px;
          // box-shadow: 0px 1px 6px #c2c0c0;
          .right-cash {
            font-size: 18px;
            font-weight: 600;
            margin-top: 1%;
            .more {
              color: #165dff;
              font-size: 13px;
              margin-left: 150px;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .three-progressBar {
            margin-top: 2%;
            height: 90%;
          }
        }
      }
      .two-bottom {
        height: 26%;
        background-color: #fff;
        border-radius: 10px;
        // box-shadow: 0px 1px 6px #c2c0c0;
        padding-bottom: 10px;
        .more {
          color: #165dff;
          font-size: 13px;
          &:hover {
            cursor: pointer;
          }
        }
        .bottom-charts {
          width: 100%;
          height: 100%;
        }
      }
    }
    .one-right {
      background-color: #fff;
      width: 32%;
      margin-left: 2%;
      .right-charts {
        width: 100%;
        height: 30%;
        display: flex;
        .left {
          width: 50%;
          height: 100%;
        }
        .right {
          width: 50%;
          height: 100%;
        }
      }
      .right-title {
        margin-left: 2%;
        margin-top: 2%;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
      }
      .right-particulars {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 15%;
        margin-bottom: 5%;
        width: 94%;
        margin-left: 3%;
        border: 1px dashed #ccc;
        border-radius: 15px;
        margin-top: 20px;
        .particulars-1 {
          text-align: center;
          .title {
            letter-spacing: 2px;
          }
          .number {
            font-weight: 500;
            font-size: 28px;
          }
        }
      }
      .switchover {
        display: flex;
        background-color: #f2f3f8;
        width: 150px;
        height: 30px;
        text-align: center;
        padding: 4px;
        line-height: 30px;
        border-radius: 5px;
        margin-bottom: 10px;
        .product {
          width: 50%;
          height: 30px;
          background-color: #fff;
          border-radius: 5px;
          color: #165dff;
          font-size: 14px;
        }
        .project {
          width: 50%;
          height: 30px;
          background-color: #fff;
          border-radius: 5px;
          color: #165dff;
          font-size: 14px;
        }
        .public {
          width: 50%;
          height: 30px;
          font-size: 14px;
        }
      }
      .percentage-table {
        .table-head {
          width: 100%;
          .head-1 {
            background-color: #f2f3f8;
            text-align: center;
            tr {
              height: 40px;
              font-size: 14px;
              th:nth-child(1) {
                width: 20%;
              }
              th:nth-child(2) {
                width: 20%;
              }
              th:nth-child(3) {
                width: 20%;
              }
              th:nth-child(4) {
                width: 40%;
              }
            }
          }
          .head-2 {
            background-color: #f2f3f8;
            text-align: center;
            tr {
              height: 40px;
              font-size: 14px;
              th:nth-child(1) {
                width: 15%;
              }
              th:nth-child(2) {
                width: 15%;
              }
              th:nth-child(3) {
                width: 20%;
              }
              th:nth-child(4) {
                width: 20%;
              }
              th:nth-child(5) {
                width: 40%;
              }
            }
          }
        }
        .table-body-border {
          height: 280px;
          overflow: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .table-body {
          width: 100%;
          .body-1 {
            background-color: #e5e8ef;
            text-align: center;
            tr {
              height: 40px;
              font-size: 13px;
              td:nth-child(1) {
                width: 20%;
              }
              td:nth-child(2) {
                width: 20%;
              }
              td:nth-child(3) {
                width: 20%;
              }
              td:nth-child(4) {
                width: 40%;
              }
            }
          }
          .body-2 {
            background-color: #e5e8ef;
            text-align: center;
            tr {
              height: 40px;
              font-size: 13px;
              td:nth-child(1) {
                width: 15%;
              }
              td:nth-child(2) {
                width: 15%;
              }
              td:nth-child(3) {
                width: 20%;
              }
              td:nth-child(4) {
                width: 20%;
              }
              td:nth-child(5) {
                width: 40%;
              }
            }
          }
        }
      }
    }
  }
}
.charts-box {
  /* width: 500px;
    height: 300px; */
  width: 100%;
  height: 100%;
}
.data_picker {
  width: 60% !important;
  position: relative !important;
  top: 25%;
}
</style>
