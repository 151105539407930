import axios from "axios";
import router from '@/router'
import { Message } from 'element-ui';

export default function(config) {
    const instance = axios.create({
        // baseURL: "http://192.168.2.169:8089/",
        // baseURL:"https://store.shiguangyimei.com/backstage",
        baseURL:"https://store.shiguangyimei.com/bk",
        // baseURL:"https://store.achinblog.cn/backstage",
        timeout: 5000, // 请求超时时间
        headers:{
          'Content-Type':'application/json',
          'authentication':localStorage.getItem("satoken")
        }
    })
    // instance.interceptors.request.use(
    //   config=>{
    //     const token = localStorage.getItem('satoken')
    //     if(token){
    //       config.headers.Authorization = `Bearer${token}`
    //     }
    //     return config
    //   }
    // )
    instance.interceptors.response.use(
        response => response,
        error => {
          const { status, data: { msg } } = error.response;
          if (status === 401) {
            Message.error(msg);
            router.replace({ path: "/about" });
          }
          return Promise.reject(error);
        }
      );
    return instance(config)
}
// export default async function(config) {
//   await new Promise(resolve => setTimeout(resolve, 500));

//   const instance = axios.create({
//     baseURL:"https://store.achinblog.cn/backstage",
//     timeout: 5000, // 请求超时时间
//   })

//   instance.interceptors.response.use(
//     response => response,
//     error => {
//       const { status, data: { msg } } = error.response;
//       if (status === 401) {
//         Message.error(msg);
//         router.replace({ path: "/" });
//       }
//       return Promise.reject(error);
//     }
//   );

//   return instance(config);
// }


  
 