import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    // 登录
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    // 收银
    path: '/money',
    name: 'money',
    component: () => import( '../views/MoneyView.vue')
  },
  {
    // 床位
    path: '/bunk',
    name: 'bunk',
    component: () => import( '../components/BunkPage.vue')
  },
  {
    // 顾客
    path: '/client',
    name: 'client',
    component: () => import( '../views/ClientView.vue')
  },
  {
    // 收银
    path: '/clientdetail',
    name: 'clientdetail',
    component: () => import( '../components/ClientDetailPage.vue')
  },
  {
    // 预约
    path: '/subscribe',
    name: 'subscribe',
    component: () => import( '../views/SubscribeView.vue')
  },
  {
    // 项目
    path: '/project',
    name: 'project',
    component: () => import( '../views/ProjectView.vue')
  },
  {
    // 套餐
    path: '/setmeal',
    name: 'setmeal',
    component: () => import( '../views/SetmealView.vue')
  },
  {
    // 员工
    path: '/staff',
    name: 'staff',
    component: () => import( '../views/StaffView.vue')
  },
  {
    // 报表
    path: '/statement',
    name: 'statement',
    component: () => import( '../views/StatementView.vue')
  },
  {
    // 设置
    path: '/set',
    name: 'set',
    component: () => import( '../views/SetView.vue')
  }
  ,
  {
    // 面诊
    path: '/face',
    name: 'face',
    component: () => import( '../views/FaceDiagnoseView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
 if(to.path == "/home") next()
 const tokenStr = window.localStorage.getItem("satoken")
 if(!tokenStr && to.path!== "/about") return next('/about')
 next()
})
// router.beforeEach((to, from, next) => {
//   if(to.path == "/home") next()
//   const tokenStr = window.localStorage.getItem("satoken")
//   if(!tokenStr && to.path!== "/home") return next('/home')
//   next()
//  })

export default router
