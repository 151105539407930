<template>
  <div class="charts-box">
    <div class="annually">
      <el-dialog title="年度报表" :visible.sync="annually">
        <table border="0" cellspacing="0">
          <thead>
            <tr>
              <th>月份</th>
              <th>金额</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(synthesizeThis, index) in thisYearList[0].date"
              :key="index"
            >
              <td>
                {{ synthesizeThis }}
              </td>
              <td>{{ thisYearList[0].cash[index] }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>合计</th>
              <th>{{ calculateTotalThis }}</th>
            </tr>
          </tfoot>
        </table>
      </el-dialog>
    </div>
    <div
      style="display: flex; justify-content: space-between; margin-bottom: 20px"
    >
      <div style="font-weight: 600; font-size: 18px">年度报表</div>
      <div class="more" @click="annually = true">查看更多</div>
    </div>
    <qiun-vue-ucharts
      class="box"
      type="column"
      :opts="opts"
      :chartData="chartData"
    />
  </div>
</template>

<script>
// 需要在您的项目内执行 npm i @qiun/vue-ucharts
import qiunVueUcharts from "@qiun/vue-ucharts";
import http from "@/http/index.js";

export default {
  props: {
    initialYear: {
      type: Number,
      required: true,
    },
  },
  components: {
    qiunVueUcharts,
  },
  data() {
    return {
      tenantNum: localStorage.getItem("tenantNum"),
      chartData: {},
      annually: false,
      thisYearList: [
        {
          date: [],
          cash: [],
        },
      ],
      //您可以通过修改 config-ucharts.js 文件中下标为 ['column'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
      opts: {
        fontColor: "#000",
        color: [
          "#1890FF",
          "#91CB74",
          "#FAC858",
          "#EE6666",
          "#73C0DE",
          "#3CA272",
          "#FC8452",
          "#9A60B4",
          "#ea7ccc",
        ],
        padding: [15, 15, 0, 5],
        enableScroll: false,
        legend: {},
        xAxis: {
          disableGrid: true,
          axisLine: false,
        },
        yAxis: {
          disableGrid: true,
          data: [
            {
              max: null,
              min: 0,
              axisLine: false,
            },
          ],
        },
        extra: {
          // column: {
          //   type: "stack",
          //   width: 30,
          //   activeBgColor: "#000000",
          //   activeBgOpacity: 0.08,
          //   labelPosition: "top",
          //   linearOpacity:0.3
          // },
          column: {
            type: "group",
            width: 30,
            activeBgColor: "#000000",
            activeBgOpacity: 0.08,
            barBorderCircle: true,
            linearType: "custom",
          },
        },
      },
    };
  },
  mounted() {
    this.getServerData();
  },
  computed: {
    yearMonth() {
      const date = new Date(this.initialYear, 0);
      const yearMonth =
        date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2);
      return yearMonth;
    },
    calculateTotalThis() {
      return this.thisYearList[0].cash.reduce(
        (total, value) => total + value,
        0
      );
    },
  },
  methods: {
    getServerData() {
      http({
        url:
          "/api/home/annualTurnover/?year=" +
          this.initialYear +
          "&tenantNum=" +
          this.tenantNum,
        method: "GET",
      }).then((res) => {
        this.thisYearList[0].date = res.data.data.yearList;
        this.thisYearList[0].cash = res.data.data.DailyTurnovers;
        let resData = {
          categories: [],
          series: [
            {
              name: "营业额",
              lineType: "dash",
              data: [],
            },
          ],
        };
        const max = Math.max(...res.data.data.DailyTurnovers);
        const thirtyPercent = max + Math.ceil(max * 0.3);
        this.opts.yAxis.data[0].max = thirtyPercent;
        resData.categories = res.data.data.yearList;
        resData.series[0].data = res.data.data.DailyTurnovers;
        this.chartData = JSON.parse(JSON.stringify(resData));
      });
    },
  },
};
</script>

<style lang="scss">
.charts-box {
  height: 100%;
  .box {
    padding-top: 10px;
  }
  .annually {
    .el-dialog__wrapper {
      .el-dialog {
        margin-top: 5vh !important;
        .el-dialog__body {
          table {
            width: 100%;
            border: 1px solid #f0f1f3;
            thead {
              text-align: center;
              background-color: #f9fafa;
              tr {
                height: 50px;

                th {
                  width: 50%;
                }
              }
            }
            tbody {
              text-align: center;
              tr {
                height: 50px;
                td {
                  border-bottom: 1px solid #f0f1f3;
                }
              }
            }
            tfoot {
              text-align: center;
              background-color: #f7f8fa;
              tr {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
  .more {
    color: #165dff;
    font-size: 13px;
    margin-left: 150px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
