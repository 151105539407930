<template>
  <div class="homepage">
    <ul class="homepage-title">
      <!-- <li class="homepage-title-li" v-for="(item, index) in list" :key="index">
        <el-badge :is-dot="showBadge" class="item" v-if="item.name == '收银'">
          <router-link class="router" :to="item.link"
            ><i :class="item.icon"></i>
            <div>{{ item.name }}</div></router-link
          >
        </el-badge>
        <router-link class="router" :to="item.link" v-if="item.name !== '收银'">
          <i :class="item.icon"></i>
          <div>{{ item.name }}</div>
        </router-link>
      </li> -->
      <li class="homepage-title-li">
        <router-link class="router" to="/home">
          <template v-if="isHome">
            <img
              src="@/assets/image/home.png"
              alt="首页"
              width="50px"
              height="50px"
            />
            <div class="color:#52b7f5">首页</div>
          </template>
          <template v-else>
            <!-- <i class="el-icon-s-home"></i> -->
            <img
              src="@/assets/image/home_gray.png"
              alt="首页"
              width="50px"
              height="50px"
              
            />
            <div>首页</div>
          </template>
        </router-link>
      </li>
      <li class="homepage-title-li">
        <el-badge :is-dot="showBadge" class="item">
          <router-link class="router" to="/money">
            <template v-if="isMoney">
              <img
                src="@/assets/image/bank.png"
                alt="收银"
                width="50px"
                height="50px"
              />
              <div class="color:#52b7f5">收银</div>
            </template>
            <template v-else>
              <!-- <i class="el-icon-coin"></i> -->
              <img
                src="@/assets/image/bank_gray.png"
                alt="收银"
                width="50px"
                height="50px"
                
              />
              <div>收银</div>
            </template>
          </router-link>
        </el-badge>
      </li>
      <li class="homepage-title-li">
        <router-link class="router" to="/face">
          <template v-if="isFace">
            <img
              src="@/assets/image/face.png"
              alt="面诊"
              width="50px"
              height="50px"
            />
            <div class="color:#52b7f5">面诊</div>
          </template>
          <template v-else>
            <!-- <i class="el-icon-date"></i> -->
            <img
              src="@/assets/image/face_gray.png"
              alt="面诊"
              width="50px"
              height="50px"
              
            />
            <div>面诊</div>
          </template>
        </router-link>
      </li>
      <li class="homepage-title-li">
        <router-link class="router" to="/client">
          <template v-if="isClient">
            <img
              src="@/assets/image/client.png"
              alt="顾客"
              width="50px"
              height="50px"
            />
            <div class="color:#52b7f5">顾客</div>
          </template>
          <template v-else>
            <!-- <i class="el-icon-user"></i> -->
            <img
              src="@/assets/image/client_gray.png"
              alt="顾客"
              width="50px"
              height="50px"
              
            />
            <div>顾客</div>
          </template>
        </router-link>
      </li>
      <li class="homepage-title-li">
        <router-link class="router" to="/project">
          <template v-if="isProject">
            <img
              src="@/assets/image/project.png"
              alt="项目"
              width="50px"
              height="50px"
            />
            <div class="color:#52b7f5">项目</div>
          </template>
          <template v-else>
            <!-- <i class="el-icon-menu"></i> -->
            <img
              src="@/assets/image/project_gray.png"
              alt="项目"
              width="50px"
              height="50px"
              
            />
            <div>项目</div>
          </template>
        </router-link>
      </li>
      <li class="homepage-title-li">
        <router-link class="router" to="/staff">
          <template v-if="isStaff">
            <img
              src="@/assets/image/staff.png"
              alt="员工"
              width="50px"
              height="50px"
            />
            <div class="color:#52b7f5">员工</div>
          </template>
          <template v-else>
            <!-- <i class="el-icon-s-check"></i> -->
            <img
              src="@/assets/image/staff_gray.png"
              alt="员工"
              width="50px"
              height="50px"
              
            />
            <div>员工</div>
          </template>
        </router-link>
      </li>
      <li class="homepage-title-li">
        <router-link class="router" to="/statement">
          <template v-if="isStatement">
            <img
              src="@/assets/image/state.png"
              alt="报表"
              width="50px"
              height="50px"
            />
            <div class="color:#52b7f5">报表</div>
          </template>
          <template v-else>
            <!-- <i class="el-icon-notebook-1"></i> -->
            <img
              src="@/assets/image/state_gray.png"
              alt="报表"
              width="50px"
              height="50px"
              
            />
            <div>报表</div>
          </template>
        </router-link>
      </li>
      <li class="homepage-title-li">
        <router-link class="router" to="/set">
          <template v-if="isSet">
            <img
              src="@/assets/image/set.png"
              alt="设置"
              width="50px"
              height="50px"
            />
            <div class="color:#52b7f5">设置</div>
          </template>
          <template v-else>
            <!-- <i class="el-icon-setting"></i> -->
            <img
              src="@/assets/image/set_gray.png"
              alt="设置"
              width="50px"
              height="50px"
              
            />
            <div>设置</div>
          </template>
        </router-link>
      </li>
     
      <div class="homepage-full">
        <div class="homepage-bottom-li">
          <img
            :src="imgSrcQuit"
            @click="quitarr"
            title="退出系统"
            @mouseover="onMouseOver"
            @mouseout="onMouseOut"
          />
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import http from "../http/index.js";
export default {
  data() {
    return {
      tenantNum: localStorage.getItem("tenantNum"),
      showBadge: false,
      imgSrcQuit: require("@/assets/image/quit_gray.png"),
      imgSrcFull: require("@/assets/icon/full_gray.png"),
      imgSrcReduce: require("@/assets/icon/reduce_gray.png"),
      list: [
        {
          name: "首页",
          icon: "el-icon-s-home",
          link: "/home",
        },
        {
          name: "收银",
          icon: "el-icon-coin",
          link: "/money",
        },
        {
          name: "面诊",
          icon: "el-icon-date",
          link: "/face",
        },
        {
          name: "顾客",
          icon: "el-icon-user",
          link: "/client",
        },
        {
          name: "项目",
          icon: "el-icon-menu",
          link: "/project",
        },
        // {
        //   name: "套餐",
        //   icon: "el-icon-document-copy",
        //   link: "/setmeal",
        // },
        {
          name: "员工",
          icon: "el-icon-s-check",
          link: "/staff",
        },
        {
          name: "报表",
          icon: "el-icon-notebook-1",
          link: "/statement",
        },
        {
          name: "设置",
          icon: "el-icon-setting",
          link: "/set",
        },
      ],
      fullscreen: false,
      serviceOrderValue: "",
    };
  },
  mounted() {
    this.getCurrentOrderQuantity();
  },
  computed: {
    isHome() {
      return this.$route.path === "/home";
    },
    isMoney() {
      return this.$route.path === "/money";
    },
    isFace() {
      return this.$route.path === "/face";
    },
    isClient() {
      return this.$route.path === "/client";
    },
    isProject() {
      return this.$route.path === "/project";
    },
    isStaff() {
      return this.$route.path === "/staff";
    },
    isStatement() {
      return this.$route.path === "/statement";
    },
    isSet() {
      return this.$route.path === "/set";
    },
  },
  methods: {
    getCurrentOrderQuantity() {
      http({
        url:
          "/api/tenant/serviceOrder/getCurrentOrderQuantity/?tenantNum=" +
          this.tenantNum,
        method: "GET",
      }).then((res) => {
        if (res.data.data !== "0") {
          this.showBadge = true;
        }
      });
    },
    onMouseOver() {
      this.imgSrcQuit = require("@/assets/image/quit.png");
    },
    onMouseOut() {
      this.imgSrcQuit = require("@/assets/image/quit_gray.png");
    },
    // onMouseOverFull() {
    //   this.imgSrcFull = require("@/assets/icon/full_blue.png");
    // },
    // onMouseOutFull() {
    //   this.imgSrcFull = require("@/assets/icon/full_gray.png");
    // },
    // onMouseOverReduce() {
    //   this.imgSrcReduce = require("@/assets/icon/reduce_blue.png");
    // },
    // onMouseOutReduce() {
    //   this.imgSrcReduce = require("@/assets/icon/reduce_gray.png");
    // },
    quitarr() {
      localStorage.removeItem("Staff");
      localStorage.removeItem("token");
      localStorage.removeItem("satoken");
      localStorage.removeItem("tenantNum");
      localStorage.removeItem("Administrator");
      localStorage.removeItem("PermissionList");
      this.$router.push("/");
    },
    screen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
  },
};
</script>
<style lang="scss">
.homepage-title {
  width: 72px;
  height: 100%;
  text-align: center;
  /* 侧边导航 */
  background: #f0f8ff;
  margin: 0px;
  padding: 0px;
  // padding-top: 20px;
  box-sizing: border-box;
  &:nth-child(1) {
    padding-top: 20%;
  }
  li {
    height: 50px;
    list-style: none;
    width: 62px;
    margin-bottom: 25px;
    letter-spacing: 0.8px;
    margin-left: 5px;
    padding-top: 15%;
  }
}
.router {
  color: #979797;
  text-decoration: none;
  display: inline-block;
  width: 50px;
  height: 52px;
  border-radius: 5px;
  padding-top: 5px;
}
.router-link-exact-active {
  color: #52b7f5;
  background-color: #fff;
  width: 50px;
  height: 52px;
  border-radius: 5px;
  padding-top: 5px;
}
.homepage-title-li-exact-active {
  background-color: #f85;
}
.homepage-title-li:hover {
  color: #f85;
}
.router:hover {
  background-color: #fff;
  width: 50px;
  height: 52px;
  border-radius: 5px;
}
.router:hover div {
  color: #52b7f5;
}
.homepage-full {
  background: rgb(240, 248, 255);
  height: 100px;
  margin-top: 80px;
  .homepage-bottom-li {
    margin-bottom: 10px;
    margin-top: 30px;
    width: 80%;
    height: 48px;
    margin-left: 10%;
    padding-top: 12%;
    border-radius: 5px;
    img{
      width: 50px;
      height: 50px;
    }
  }
}
</style>
