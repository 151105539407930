<template>
  <div id="app">
    <!-- <audio src="" id="eventAudio"></audio> -->
    <div id="nav"></div>
    <transition name="slide-left" mode="out-in">
      <router-view v-if="isbool" />
    </transition>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isbool: true,
    };
  },
  methods: {
    reload() {
      this.isbool = false;
      this.$nextTick(() => {
        //下次更新循环后加载
        this.isbool = true;
      });
    },
    // overallSituation() {
    //   this.playAudio();
    // },
  },
};
</script>
<style lang="scss">
// html {
//   cursor: url(assets/image/mouse.ico), auto;
// }
// button{
//   cursor: url("https://wx1.shiguangyimei.com/imgs/ut7691/2022-11-09/f191-3168-4ae6-8ada-fdefe_shubiao.ico"),
//     auto;
// }
#app {
  margin: 0;
  padding: 0;
  #nav {
    margin: 0;
    padding: 0;
  }
}
// .slide-left-enter {
//   opacity: 0.5;
//   -webkit-transform: translate(30px, 0);
//   transform: translate(30px, 0);
// }
// .slide-left-enter-active {
//   transition: all 0.5s ease;
// }
// .slide-left-leave-to {
//   opacity: 0.5;
//   -webkit-transform: translate(-30px, 0);
//   transform: translate(-30px, 0);
// }
// .slide-left-leave-active {
//   transition: all 0.5s ease;
// }
</style>
