/* eslint-disable */
/*
 * 判断是否数字
 */
export const isNumber = (obj) => {
    return !isNaN(parseFloat(obj)) && isFinite(obj);
   };
export const setDecimalBitPro=(num)=> {
	if (num === 0) {
	  return num + ".00";
	} else if (num === undefined) {
	  return 0;
	} else {
	  const [integerPart, decimalPart] = num.toString().split(".");
	  let result = "";

	  // 处理整数部分
	  const len = integerPart.length;
	  for (let i = len - 1, j = 0; i >= 0; i--, j++) {
		result = integerPart[i] + result;
		if (j % 3 === 2 && i !== 0) {
		  result = "," + result;
		}
	  }

	  // 添加小数部分
	  if (decimalPart !== undefined) {
		const roundedNum = (
		  Math.round(parseFloat("0." + decimalPart) * 100) / 100
		).toFixed(2);
		result += "." + roundedNum.split(".")[1];
	  } else {
		result += ".00";
	  }

	  return result;
	}
  }
/*
 * 保留n位小数,默认2位
 * 123.132  => 123.13
 */
export const setDecimalBit = (number, n = 2) => {
    if (isNumber(number)) {
     number = (Math.round(number * Math.pow(10, n)) * Math.pow(0.1, n)).toFixed(n);
     number = number.toString().split('.');
     number = [number[0], number[1] ? number[1].slice(0, n) : ""].join('.');
    }
    return number;
   };

   //函数防抖
export const debounce = (fn, delay) => {
	// 记录上一次的延时器
	var timer = null;
	var delay = delay || 200;
	return function() {
		var args = arguments;
		var that = this;
		// 清除上一次延时器
		clearTimeout(timer)
		timer = setTimeout(function() {
			fn.apply(that, args)
		}, delay);
	}
}
//函数节流
export const throttle = (func = () => {}, delay = 0) => {
	var timer = null;
	return function() {
		var context = this;
		var args = arguments;
		if (!timer) {
			timer = setTimeout(function() {
				func.apply(context, args);
				timer = null;
			}, delay);
		}
	}
}

