import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    input: '',
    Number1: 0,
    Number2: 0,
    types: '+'
  },
  mutations: {
    handles1 (state, n) {
      state.input += n.toString()
    },
    // handleaddstore (state) {
    //   if (state.input !== '') {
    //     state.Number1 = parseFloat(state.input)
    //     state.input = ''
    //     state.types = '+'
    //     console.log('加法')
    //   }
    // },
    handleresultStore (state) {
      state.Number2 = parseFloat(state.input)
      if (state.types === '+') {
        state.input = state.Number1 + state.Number2
      } else if (state.types === '-') {
        state.input = state.Number1 - state.Number2
      } else if (state.types === '*') {
        state.input = state.Number1 * state.Number2
      } else if (state.types === '/') {
        state.input = state.Number1 / state.Number2
      } else {
        return null
      }
    },
    handleCCC (state) {
      state.input = ''
      state.Number1 = 0
      state.Number2 = 0
    },
    // handlesubstore (state) {
    //   if (state.input !== '') {
    //     state.Number1 = parseFloat(state.input)
    //     state.input = ''
    //     console.log('减法')
    //     state.types = '-'
    //   }
    // },
    // handlechengstore (state) {
    //   if (state.input !== '') {
    //     state.Number1 = parseFloat(state.input)
    //     state.input = ''
    //     console.log('乘法')
    //     state.types = '*'
    //   }
    // },
    // handlechustore (state) {
    //   if (state.input !== '') {
    //     state.Number1 = parseFloat(state.input)
    //     state.input = ''
    //     console.log('除法')
    //     state.types = '/'
    //   }
    // },
    handleclearinput (state) {
      state.input = state.input.toString()
      if (state.input.length !== 0) {
        state.input = state.input.substr(0, state.input.length - 1)
      } else {
        return alert('没有内容了')
      }
    }
  },
  actions: {},
  getters: {},
  modules: {}
})