<template>
  <div class="charts-box">
    <qiun-vue-ucharts type="ring" :opts="opts" :chartData="chartData" />
  </div>
</template>

<script>
// 需要在您的项目内执行 npm i @qiun/vue-ucharts
import qiunVueUcharts from "@qiun/vue-ucharts";
import http from "@/http/index.js";
export default {
  components: {
    qiunVueUcharts,
  },
  data() {
    return {
      tenantNum: localStorage.getItem("tenantNum"),
      chartData: {},
      //您可以通过修改 config-ucharts.js 文件中下标为 ['ring'] 的节点来配置全局默认参数，如都是默认参数，此处可以不传 opts 。实际应用过程中 opts 只需传入与全局默认参数中不一致的【某一个属性】即可实现同类型的图表显示不同的样式，达到页面简洁的需求。
      opts: {
        rotate: false,
        rotateLock: false,
        color: [
          "#1890FF",
          "#91CB74",
          "#FAC858",
          "#EE6666",
          "#73C0DE",
          "#3CA272",
          "#FC8452",
          "#9A60B4",
          "#ea7ccc",
        ],
        padding: [5, 5, 5, 5],
        dataLabel: true,
        enableScroll: false,
        legend: {
          show: false,
          position: "right",
          lineHeight: 25,
        },
        title: {
          name: "产品",
          fontSize: 18,
          color: "#000000",
        },
        subtitle: {
          
        },
        extra: {
          ring: {
            ringWidth: 20,
            activeOpacity: 0.5,
            activeRadius: 10,
            offsetAngle: 0,
            labelWidth: 10,
            border: true,
            borderWidth:1,
            borderColor: "#FFFFFF",
            linearType: "custom",
            customRadius:50,
          },
        },
      },
      DateValue: "",
    };
  },
  mounted() {
    this.getCurrentYearMonth();
    this.getServerData();
  },
  methods: {
    getCurrentYearMonth() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      this.DateValue = `${year}-${month}`;
    },
    getServerData() {
      http({
        url: "/api/home/ProportionProductConsumption",
        method: "GET",
        params: {
          tenantNum: this.tenantNum,
          month: this.DateValue,
        },
      }).then((res) => {
        let resl = {
            series:[
                {
                    data:[]
                }
            ]
        }
        resl.series[0].data = res.data.data.ProductpieChartDataUtils
        this.chartData = JSON.parse(JSON.stringify(resl));
      });
    },
  },
};
</script>

<style scoped>
.charts-box{
    width: 100%;
    height: 100%;
}
</style>
