<template>
  <div class="denied">
    <img src="@/assets/image/jurisdiction.png" alt="" />
    <div class="title">你没有权限访问</div>
    <div class="txt">{{message}}</div>
  </div>
</template>

<script>
export default {
   props: {
    message: String,
  },
};

</script>

<style lang="scss">
.denied{
  width: 100%;
    text-align: center;
    margin-top: 250px;
    .title{
        color: rgba(0,0,0,0.7);
        font-size: 28px;
        margin-top: 20px;
    }
    .txt{
        color: #979797;
        margin-top: 5px;
    }
}
</style>
